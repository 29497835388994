<template>
  <div>
    <gmap-map
      :center="center"
      :zoom="12"
      style="width:80%;  height: 250px;"
    >
      <gmap-marker
        :key="index"
        v-for="(m, index) in markers"
        :position="m"
        @click="center=m"
      ></gmap-marker>
    </gmap-map>

  </div>
</template>

<script>


export default {
  name: "GoogleMap",
  data() {
    return {
      center: { lat: 45.508, lng: -73.587 }, 
      markers: [],
      currentPlace: null
    };
  },

  mounted() {
    this.geolocate();
  },


  methods: {
    setPlace(place) {
      this.currentPlace = place;
    },
    geolocate: function() {

        navigator.geolocation.getCurrentPosition(position => {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
        });
        
        this.markers = [
          {
              lat: 44.42391545041855,
              lng: 26.092444898127983,
              label: 'Clinica Oana Nicolau',
          }
      ];

    }
  }
};
</script>