<template>
    <v-app>
      <v-app-bar fixed height="90" flat app class="app-bar-color" absolute>
        <v-icon class="hidden-lg-and-up ml-4" @click.stop="drawer = !drawer">fas fa-bars</v-icon>
        <v-navigation-drawer
          v-model="drawer"
          absolute
          temporary
          app
          width="300"
        >
          <v-list
            nav
          >
            <v-list-item-group
             active-class="white--text "
            >
              <v-list-item v-model="group">
                <v-btn class="ma-2" text plain exact :to="{name:'home'}" >
                    Home
                </v-btn> 
              </v-list-item>

              <v-list-item>
                 <v-btn class="ma-2" text plain exact :to="{name:'about'}">
                      Despre mine
                </v-btn>
              </v-list-item>

              <v-list-item :ripple="false">
                <v-row
                  justify="center"
                  style="min-height: 20px;"
                >
                  <v-col >
                    <v-btn
                      class="ml-2"
                      text
                      plain
                      :ripple="false"
                      @click="expand = !expand"
                    >
                     Relatii
                      <v-icon right dense>fas fa-caret-down</v-icon>
                    </v-btn>

                    <v-expand-transition>
                      <v-card v-show="expand"
                        height="100"
                        width="200"
                        class="mx-auto"
                        flat
                        :ripple="false"
                        >
                        <v-list-item>
                          <v-btn :ripple="false" text plain exact :to="{name:'home'}" >
                              Intalniri
                          </v-btn>              
                        </v-list-item>

                        <div class="divider">
                          <div class="divider-dropdown"></div>
                        </div>

                        <v-list-item>
                          <v-btn :ripple="false" text plain exact :to="{name:'home'}" >
                              Cupluri
                          </v-btn> 
                        </v-list-item>  
                      </v-card>
                    </v-expand-transition>
                  </v-col>
                </v-row> 
              </v-list-item>

              <v-list-item :ripple="false">
                <v-row
                  justify="center"
                  style="min-height: 20px;"
                >
                  <v-col >
                    <v-btn
                      class="ma-2"
                      text
                      plain
                      :ripple="false"
                      @click="expand2 = !expand2"
                    >
                     A fi parinte
                      <v-icon right dense>fas fa-caret-down</v-icon>
                    </v-btn>

                    <v-expand-transition>
                      
                      <v-card v-show="expand2"
                        height="100"
                        width="200"
                        class="mx-auto"
                        flat
                        :ripple="false">
                        <v-list-item>
                          <v-btn :ripple="false" text plain exact :to="{name:'home'}" >
                              nastere
                          </v-btn>              
                        </v-list-item>

                        <div class="divider">
                          <div class="divider-dropdown"></div>
                        </div>

                        <v-list-item>
                          <v-btn :ripple="false" text plain exact :to="{name:'home'}" >
                              etapele cresterii
                          </v-btn> 
                        </v-list-item>  
                      </v-card>
                    </v-expand-transition>
                  </v-col>
                </v-row> 
              </v-list-item>

              <v-list-item :ripple="false">
                <v-row
                  justify="center"
                  style="min-height: 20px;"
                >
                  <v-col >
                    <v-btn
                      class="ml-2"
                      text
                      plain
                      :ripple="false"
                      @click="expand3 = !expand3"
                    >
                     dezvoltare personala
                      <v-icon right dense>fas fa-caret-down</v-icon>
                    </v-btn>

                    <v-expand-transition>
                      
                      <v-card v-show="expand3"
                        height="100"
                        width="200"
                        class="mx-auto"
                        flat
                        :ripple="false">
                        <v-list-item>
                          <v-btn :ripple="false" text plain exact :to="{name:'home'}" >
                              planuirea carierei
                          </v-btn>              
                        </v-list-item>

                        <div class="divider">
                          <div class="divider-dropdown"></div>
                        </div>

                        <v-list-item>
                          <v-btn :ripple="false" text plain exact :to="{name:'home'}" >
                              obiceiuri zilnice
                          </v-btn> 
                        </v-list-item>  
                      </v-card>
                    </v-expand-transition>
                  </v-col>
                </v-row> 
              </v-list-item>

              <v-list-item :ripple="false">
                <v-row
                  justify="center"
                  style="min-height: 20px;"
                  
                >
                  <v-col >
                    <v-btn
                      text
                      plain
                      @click="expand4 = !expand4"
                      class="ma-2"
                      :ripple="false"
                    >
                     terapie zilnica
                      <v-icon right dense>fas fa-caret-down</v-icon>
                    </v-btn>

                    <v-expand-transition>
                      
                      <v-card v-show="expand4"
                        height="160"
                        width="200"
                        class="mx-auto"
                        flat
                        :ripple="false">
                        <v-list-item>
                          <v-btn :ripple="false" text plain exact :to="{name:'home'}" >
                              anxietate
                          </v-btn>              
                        </v-list-item>
                        <div class="divider">
                          <div class="divider-dropdown"></div>
                        </div>
                        <v-list-item>
                          <v-btn :ripple="false" text plain exact :to="{name:'home'}" >
                              depresie
                          </v-btn> 
                        </v-list-item>  
                        <div class="divider">
                          <div class="divider-dropdown"></div>
                        </div>
                        <v-list-item>
                          <v-btn :ripple="false" text plain exact :to="{name:'home'}" >
                              fobii
                          </v-btn> 
                        </v-list-item>  
                      </v-card>
                    </v-expand-transition>
                  </v-col>
                </v-row> 
              </v-list-item>

              <v-list-item class="ml-2">
                 <v-btn :ripple="false" text plain exact :to="{name:'resources'}" >
                      Resurse
                 </v-btn> 
              </v-list-item>  

            </v-list-item-group>
          </v-list>
        </v-navigation-drawer> 
        <Header/>
      </v-app-bar>  
        <v-content class="app-body">
            
            <router-view>
              <Homepage/>
            </router-view>

            
        </v-content>  
      <Footer/>
    </v-app>  
</template>

<script>

import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import About from './view/About.vue'
import Homepage from './view/Homepage.vue'
import Resources from './view/Resources.vue'


export default {
  name: 'App',
  data: () => ({
      drawer: false,
      group: null,
      expand: false,
      expand2: false,
      expand3: false,
      expand4: false,
    }),

    watch: {
      group () {
        this.drawer = false
      },
    },

  components: {
    Header,
    Footer,
    About,
    Homepage,
    Resources,
  
  },
  created: function () {
    this.$http.interceptors.response.use(undefined, function (err) {
      return new Promise(function (resolve, reject) {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch("logout")
        }
        throw err;
      });
    });
  }
}
</script>

<style lang="scss">

  $font-stack: Montserrat, sans-serif;
  $primary-color: #C5B5D9;

  $hairline-weight: 100;
  $thin-weight:     200;
  $light-weight:    300;
  $normal-weight:   400;
  $medium-weight:   500;
  $semibold-weight: 600;
  $bold-weight:     700;
  $xbold-weight:    800;
  $black-weight:    900;

  .app-body{
    background-color: #FAFAFA;
    font-family: $font-stack;
  }

  .app-bar-color{
    background-color: #FFFFFF !important;
  }

  

</style>
