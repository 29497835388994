import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import * as VueGoogleMaps from "vue2-google-maps"
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import store from "./store";
import Homepage from './view/Homepage.vue';
import About from "./view/About.vue";
import Login from "./view/Login.vue";
import Resources from "./view/Resources.vue";
import Editor from "./components/Editor";

Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    {path: "/", name: "home", component: Homepage},
    {path: '/about', name: "about", component: About},
    {path: '/resources', name: "resources", component: Resources},
    {path: '/login', name: "login", component: Login},
    {path: '/editor', name: "editor", component: Editor, meta: {requiresAuth: true}},
  ],
});
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})


Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBEFms8fpxJzDmcFy7SjpICCwIqwaRIAKY",
    libraries: "places"
  }
});

Vue.config.productionTip = false


Vue.prototype.$http = axios;
const http = Vue.prototype.$http
// LOGIN
let token = localStorage.getItem('token')
if (token) {
  //how the header for the api looks like should be ("Authorization" : Token XYZ)
  Vue.prototype.$http.defaults.headers.common['Authorization'] = "Token" +" "+ token
}
new Vue({
  vuetify,
  store,
  http,
  render: h => h(App),
  router: router
}).$mount('#app')
