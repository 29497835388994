<template>
  <div>
    <v-row>
       <Carousel/>
    </v-row> 

    <v-row justify="center" align-content="center" class="pa-12" style="background-color: #FCEEFF;">
      <v-col cols="12" md="6" lg="3" class="mt-12  " >
        <vertical-card>
          <v-img slot="card-image" class="resize-img"
            src="/img/daily_therapy.jpg"
          ></v-img>
          
          <h5 slot="card-title">Terapie zilnica</h5>
          
          <p slot="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
          </p>
        </vertical-card>
      </v-col>

      <v-col cols="12" md="6" lg="3" class="mt-12 " >
        <vertical-card>
          <v-img slot="card-image" class="resize-img"
          src="/img/personal_development.jpg"
          ></v-img>
          
          <h5 slot="card-title" display-1 >Dezvoltare personala  </h5>
          <!-- <h5 slot="card-title" display-1 class="hidden-lg-and-up">Dezvoltare <br>Personala  </h5> -->
          
          <p slot="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
          </p>
        </vertical-card>
      </v-col>
      <v-col cols="12" md="6" lg="3" class="mt-12  mb-12">
        <vertical-card>
          <v-img slot="card-image" class="resize-img"
            src="/img/relationships.jpg"
          ></v-img>
          
          <h5 slot="card-title">Relatii</h5>
          
          <p slot="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
          </p>
        </vertical-card>
      </v-col>      
    </v-row>   

     <v-row justify="center">
        <v-col cols="12">
            <h1 class="black--text font-weight-bold text-center  mt-12 ">Cele mai recente postari</h1>
        </v-col>
    </v-row>

    <v-row justify="center" align-content="center" class="pa-12 grey lighten-5 mb-10">
      <v-col>
        <horizontal-card class="mb-6">
          
        </horizontal-card>
      </v-col> 
      <v-col>
        <horizontal-card class="mb-6">
          
        </horizontal-card>
      </v-col>
      <v-col>
        <horizontal-card class="mb-6">
          
        </horizontal-card>
      </v-col> 
    </v-row>   
  </div> 
</template>

<script>

import Carousel from '../components/Carousel.vue'
import HorizontalCard from '../components/HorizontalCard.vue'
import VerticalCard from '../components/VerticalCard.vue'

  export default {
    name: 'Homepage',
    components: {Carousel, VerticalCard, HorizontalCard}
  }
</script>

<style lang="scss">
    .resize-img{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      overflow: hidden;
      height: 200px;
  }

   .divider-title{
     border-top: 2px solid #C5B5D9;
     width: 100px;
    
    
  }
</style>