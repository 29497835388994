<template>
    <div id="editor_page">

        <div class="form-group">
            <label>Article title</label>
            <input required type="text" class="form-control form-control-lg" v-model="article_title" />
        </div>
        <div class="form-group">
            <label>Main image</label>
        </div>
        <div class="form-group">
            <label>Summary</label>
            <input  type="text" class="form-control form-control-lg" v-model="summary" />
        </div>
        <div class="form-group">
            <b-dropdown id="dropdown-3" text="Category" class="m-md-2 dropdown-height">
                <b-dropdown-item v-for="selected_category in getCategories()"
                                 :key="selected_category"
                                 @click="category = selected_category"
                >{{selected_category}}</b-dropdown-item>
            </b-dropdown>
            <label>{{category}}</label>
        </div>
        <div class="form-group">
            <b-dropdown id="dropdown-1" text="Is visible" class="m-md-2" >
                <b-dropdown-item @click="is_visible = true">True</b-dropdown-item>
                <b-dropdown-item @click="is_visible = false">False</b-dropdown-item>
            </b-dropdown>
            <label>{{is_visible}}</label>
        </div>


        <vue-editor
                id="editor"
                useCustomImageHandler
                @image-added="handleImageAdded"
                v-model="htmlForEditor"
        >
        </vue-editor>
        <v-btn @click="handleSavingContent" elevation="24"> Save Content </v-btn>
        <div v-html="savedContent"></div>
    </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import axios from "axios";
    export default {
    components: {
        VueEditor,
    },

    data() {
        return {
            content: "<h1>Some initial content</h1>",
            htmlForEditor: "",
            savedContent: "",
            //article data
            article_title: "",
            main_image: "",
            summary: "",
            category: "Intalniri",
            is_visible: "true"
        };
    },

    methods: {
        handleSavingContent: function() {
            // You have the content to save
            console.log(this.htmlForEditor);
            let self=this
            this.savedContent = this.htmlForEditor
            //make mock data
            let text = this.savedContent
            let article = {
                "title": self.article_title,
                "main_image": self.main_image,
                "summary": self.summary,
                "category": self.category,
                "is_visible": self.is_visible,
                "article_text": text
            }
            this.$http(
                {url: 'http://localhost:8000/articles/', data: article, method: 'POST' })
                .then(resp => {
                console.log("RESPONSE IS:")
                console.log(resp)
                })
        },
        handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
            // An example of using FormData
            // NOTE: Your key could be different such as:
            // formData.append('file', file)

            var formData = new FormData();
            formData.append("image", file);
            console.log("ADDED IMAGE")
            console.log(formData)
            axios({
                url: "https://fakeapi.yoursite.com/images",
                method: "POST",
                data: formData
            })
                .then(result => {
                    const url = result.data.url; // Get url from response
                    Editor.insertEmbed(cursorLocation, "image", url);
                    resetUploader();
                })
                .catch(err => {
                    console.log(err);
                });
        },

        getCategories: function(){
            return ["Intalniri", "Cupluri", "Nasterea", "Etapele Cresteri","Planificare carierei", "Obiceiuri zilnice", "Anxietate", "Depresie", "Fobii" ];
        }
    }
};
</script>
<style scoped>
    .dropdown-height /deep/ .dropdown-menu{
        max-height: 100px;
        overflow-y: auto;
    }
</style>