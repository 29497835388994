<template>
    <v-toolbar height="90" flat>
      <v-row  class="hidden-md-and-down">
        <v-col cols="12" lg="5">
          <v-toolbar-title class="ml-16 title-text" style="font-size: 1.4em">
            <router-link style="text-decoration: none; color: #6937A7" exact :to="{name:'home'}">popescu-donea alice Psihoterapeut</router-link>
          </v-toolbar-title>
        </v-col>
        
        <v-col cols="12" lg="7">
          <v-btn text plain exact :to="{name:'home'}" >
              Home
          </v-btn> 
                    
          <v-btn text plain exact :to="{name:'about'}" >
                Despre mine
          </v-btn>

          <v-menu offset-y class="text-center">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                plain
                v-bind="attrs"
                v-on="on"
                elevation="0"
              >
                Relatii
                <v-icon right dense>fas fa-caret-down</v-icon>
              </v-btn>
            </template>
            <v-list color="grey lighten-5">
              <v-list-item>
                <v-btn text plain exact :to="{name:'home'}" >
                    Intalniri
                </v-btn>                       
              </v-list-item>

              <div class="divider">
                <div class="divider-dropdown"></div>
              </div>

              <v-list-item>
                <v-btn text plain exact :to="{name:'home'}" >
                    Cupluri
                </v-btn> 
              </v-list-item>  
            </v-list>
          </v-menu>

          <v-menu offset-y class="text-center">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                plain
                v-bind="attrs"
                v-on="on"
                elevation="0"
              >
                A fi parinte
                <v-icon  right dense>fas fa-caret-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-btn text plain exact :to="{name:'home'}" >
                    Nasterea
                </v-btn> 
              </v-list-item> 

              <div class="divider">
                <div class="divider-dropdown"></div>
              </div>

              <v-list-item>
                <v-btn text plain exact :to="{name:'home'}" >
                    Etapele cresteri
                </v-btn> 
              </v-list-item>  
            </v-list>
          </v-menu>

          <v-menu offset-y class="text-center">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                plain
                v-bind="attrs"
                v-on="on"
                elevation="0"
              >
                Dezvoltare personala
                <v-icon  right dense>fas fa-caret-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-btn text plain exact :to="{name:'home'}" >
                    Planuirea carierei
                </v-btn> 
              </v-list-item> 

              <div class="divider">
                <div class="divider-dropdown"></div>
              </div>

              <v-list-item>
                <v-btn text plain exact :to="{name:'home'}" >
                    Obiceiuri zilnice
                </v-btn> 
              </v-list-item>   
            </v-list>
          </v-menu>

          <v-menu offset-y class="text-center">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                plain
                v-bind="attrs"
                v-on="on"
                elevation="0"
              >
                Terapie zilnica
                <v-icon  right dense>fas fa-caret-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-btn text plain exact :to="{name:'home'}" >
                    Anxietate
                </v-btn> 
              </v-list-item> 

              <div class="divider">
                <div class="divider-dropdown"></div>
              </div>

              <v-list-item>
                <v-btn text plain exact :to="{name:'home'}" >
                    Depresie
                </v-btn> 
              </v-list-item>

              <div class="divider">
                <div class="divider-dropdown"></div>
              </div>

              <v-list-item>
                <v-btn text plain exact :to="{name:'home'}" >
                    Fobii
                </v-btn> 
              </v-list-item>     
            </v-list>
          </v-menu>

          <v-btn text plain exact :to="{name:'resources'}" >
              Resurse
          </v-btn>

          <!-- <v-btn text plain exact v-if="!isLoggedIn" :to="{name:'login'}" >
            Login
          </v-btn> -->
          <v-btn text plain exact v-if="isLoggedIn" @click="logout">
            Logout
          </v-btn>
          <v-btn text plain exact v-if="isLoggedIn" :to="{name:'editor'}">
            Editor
          </v-btn>
        </v-col>
      </v-row>
      
      <!-- ths is title for mobile -->
      <v-row class="hidden-lg-and-up align-center mb-2">
        <v-col cols="12">
            <router-link class="title-text" style="text-decoration: none; font-size: 1.3em; color: #6937A7" exact :to="{name:'home'}">popescu-donea alice Psihoterapeut</router-link>
        </v-col>
      </v-row>
  </v-toolbar>
 

</template>

<script>
  import { Slide } from 'vue-burger-menu'

  export default {
      name: 'Header',
      data: () => ({
      expand: false,
      expand2: false,
    }),
      
      components: {
        Slide 
    },
    computed : {
      isLoggedIn : function(){ return this.$store.getters.isLoggedIn}
    },
    methods: {
      logout: function () {
        this.$store.dispatch('logout')
                .then(() => {
                  this.$router.push('/login')
                })
      }
    },
      
  }
</script>

<style lang="scss">

$font-stack: Montserrat, sans-serif;
$medium-weight:   500;
$primary-color: #C5B5D9;
$semibold-weight: 600;
$bold-weight:     700;

.title-text{
  font-family: $font-stack;
  font-weight: $semibold-weight;

  @media screen and (max-width: 960px) {
    text-decoration: none;
  }
  text-transform: uppercase;
}

.dropdown-buttons{
  text-decoration: none;
  border-style: none;
  background-color: tra;
}

.divider{
  position: relative;
}

.divider-dropdown{
  border-top: 2px solid $primary-color;
  width: 40px;
  margin-left: 33px;

  @media screen and (max-width: 960px)
  {
    border-top: 2px solid $primary-color;
    width: 40px;
    margin-left: 26px;
  }
}

.divider-card{
  border-top: 2px solid $primary-color;
  width: 60px;
  margin-left: 1px;

}
.align-center{
  position: absolute; 
  left: 0; 
  right: 0; 
  left: 0; 
  text-align: center;
}

.underline-categories{
  width: 100px;
  height: 100px;
  background: red;
  transition: width 2s;
}

.underline-categories:hover{
  width: 200px;
}


</style> 
