<template>
  <v-carousel
    cycle
    height="500"
    hide-delimiter-background
    show-arrows-on-hover
    v-slot: next-icon="mdi-chevron-right" prev-icon="mdi-chevron-left"
  >
    <v-carousel-item
      v-for="(item,i) in items"
      :key="i"
      :src="item.src"
      class="slide-enter-active " 
      gradient="to top right, rgba(100,115,201,.1), rgba(255, 255, 255, .2)"
    >
    
     <v-sheet
        color="transparent"
        height="100%"
        tile
      >
        <v-row
          class="fill-height"
          align="center"
          justify="center"
        >
          <div class="text-h1 text-white">
            {{ item.text }}
          </div>
        </v-row>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>

<style scoped lang="scss">
  .slide-enter-active{
    transition: all 1s ease;
  }
</style>
<script>
  export default {
  name: 'Carousel',
     data () {
      return {
        items: [
          {
            src: '/img/slider_photo4.jpg',
            text: 'Invata sa te bucuri de viata la maximum'
          },
          {
            src: '/img/slider_photo5.jpg',
            text: 'Ceva citat si aici'
          },
          {
            src: '/img/slider_photo3.jpg',
            text: 'Asta ar fi alt citat'
          },
          {
            src: '/img/slider_photo1.jpg',
            text: 'Asta ar fi unul dintre citate'
          },
        ],
      }
    },
  }
</script>