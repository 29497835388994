var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar',{attrs:{"height":"90","flat":""}},[_c('v-row',{staticClass:"hidden-md-and-down"},[_c('v-col',{attrs:{"cols":"12","lg":"5"}},[_c('v-toolbar-title',{staticClass:"ml-16 title-text",staticStyle:{"font-size":"1.4em"}},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"#6937A7"},attrs:{"exact":"","to":{name:'home'}}},[_vm._v("popescu-donea alice Psihoterapeut")])],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"7"}},[_c('v-btn',{attrs:{"text":"","plain":"","exact":"","to":{name:'home'}}},[_vm._v(" Home ")]),_c('v-btn',{attrs:{"text":"","plain":"","exact":"","to":{name:'about'}}},[_vm._v(" Despre mine ")]),_c('v-menu',{staticClass:"text-center",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"plain":"","elevation":"0"}},'v-btn',attrs,false),on),[_vm._v(" Relatii "),_c('v-icon',{attrs:{"right":"","dense":""}},[_vm._v("fas fa-caret-down")])],1)]}}])},[_c('v-list',{attrs:{"color":"grey lighten-5"}},[_c('v-list-item',[_c('v-btn',{attrs:{"text":"","plain":"","exact":"","to":{name:'home'}}},[_vm._v(" Intalniri ")])],1),_c('div',{staticClass:"divider"},[_c('div',{staticClass:"divider-dropdown"})]),_c('v-list-item',[_c('v-btn',{attrs:{"text":"","plain":"","exact":"","to":{name:'home'}}},[_vm._v(" Cupluri ")])],1)],1)],1),_c('v-menu',{staticClass:"text-center",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"plain":"","elevation":"0"}},'v-btn',attrs,false),on),[_vm._v(" A fi parinte "),_c('v-icon',{attrs:{"right":"","dense":""}},[_vm._v("fas fa-caret-down")])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-btn',{attrs:{"text":"","plain":"","exact":"","to":{name:'home'}}},[_vm._v(" Nasterea ")])],1),_c('div',{staticClass:"divider"},[_c('div',{staticClass:"divider-dropdown"})]),_c('v-list-item',[_c('v-btn',{attrs:{"text":"","plain":"","exact":"","to":{name:'home'}}},[_vm._v(" Etapele cresteri ")])],1)],1)],1),_c('v-menu',{staticClass:"text-center",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"plain":"","elevation":"0"}},'v-btn',attrs,false),on),[_vm._v(" Dezvoltare personala "),_c('v-icon',{attrs:{"right":"","dense":""}},[_vm._v("fas fa-caret-down")])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-btn',{attrs:{"text":"","plain":"","exact":"","to":{name:'home'}}},[_vm._v(" Planuirea carierei ")])],1),_c('div',{staticClass:"divider"},[_c('div',{staticClass:"divider-dropdown"})]),_c('v-list-item',[_c('v-btn',{attrs:{"text":"","plain":"","exact":"","to":{name:'home'}}},[_vm._v(" Obiceiuri zilnice ")])],1)],1)],1),_c('v-menu',{staticClass:"text-center",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"plain":"","elevation":"0"}},'v-btn',attrs,false),on),[_vm._v(" Terapie zilnica "),_c('v-icon',{attrs:{"right":"","dense":""}},[_vm._v("fas fa-caret-down")])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-btn',{attrs:{"text":"","plain":"","exact":"","to":{name:'home'}}},[_vm._v(" Anxietate ")])],1),_c('div',{staticClass:"divider"},[_c('div',{staticClass:"divider-dropdown"})]),_c('v-list-item',[_c('v-btn',{attrs:{"text":"","plain":"","exact":"","to":{name:'home'}}},[_vm._v(" Depresie ")])],1),_c('div',{staticClass:"divider"},[_c('div',{staticClass:"divider-dropdown"})]),_c('v-list-item',[_c('v-btn',{attrs:{"text":"","plain":"","exact":"","to":{name:'home'}}},[_vm._v(" Fobii ")])],1)],1)],1),_c('v-btn',{attrs:{"text":"","plain":"","exact":"","to":{name:'resources'}}},[_vm._v(" Resurse ")]),(_vm.isLoggedIn)?_c('v-btn',{attrs:{"text":"","plain":"","exact":""},on:{"click":_vm.logout}},[_vm._v(" Logout ")]):_vm._e(),(_vm.isLoggedIn)?_c('v-btn',{attrs:{"text":"","plain":"","exact":"","to":{name:'editor'}}},[_vm._v(" Editor ")]):_vm._e()],1)],1),_c('v-row',{staticClass:"hidden-lg-and-up align-center mb-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('router-link',{staticClass:"title-text",staticStyle:{"text-decoration":"none","font-size":"1.3em","color":"#6937A7"},attrs:{"exact":"","to":{name:'home'}}},[_vm._v("popescu-donea alice Psihoterapeut")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }