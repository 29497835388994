<template>
   <!-- <v-card 
    class="mx-auto"
    max-width="400px" max-height="250px">
    <v-img height="200px" max-width="200px">
      <slot name="card-image"></slot>
    </v-img>

    <div class="pa-4">
      <v-card-title class="display-text">
        <slot name="card-title"></slot>
      </v-card-title> 
    </div>
     
     
      <v-btn
        color=""
        text
        class="ma-4"
      >
        Learn more
        <v-icon class="ml-4" size="25px" color="primary">fas fa-long-arrow-alt-right</v-icon>
      </v-btn>
   </v-card>  -->
   <v-card
    class="mx-auto"
    max-width="450"
   
    elevation="1"
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h5 mb-1">
          Headline 5
        </v-list-item-title>
        
      </v-list-item-content>

      <v-img height="200px" max-width="200" src="/img/daily_therapy.jpg" >
        <slot name="card-image"></slot>
      </v-img>
    </v-list-item>

    <v-card-actions>
      <v-btn
        plain
        text
      >
        Read more
      </v-btn>
    </v-card-actions>
  </v-card> 
</template>

<script>

export default{
    name: 'HorizontalCard',
}

</script>

<style scoped lang="scss">
   
</style>