<template>
  <v-footer
    padless
    height="396"
    style="background-color: #C5B5D9"
  >
    <v-row class="ml-4 title-footer">
      <v-col
        cols="12"
        offset-md="1"
        offset-sm="0"
        offset-lg="2"
        sm="6"
        md="6"
        lg="4"
        xs="12"
        class="footer-text" >
        <h3 class="mb-10 mt-6"> Gaseste-ma aici!
          <div class="divider">
                <div class="divider-footer"></div>
          </div>
        </h3>
        <div id="app" class="mb-6">
          <GoogleMap />
        </div>
      </v-col>
      
      <v-col 
        cols="12" 
        xs="12"
        sm="6"
        md="6"
        lg="5"
        offset-lg="1"
        class="footer-text footer-text--xs">
        
          <h3 class="mb-10 mt-6">Contacteaza-ma 
              <div class="divider">
                <div class="divider-footer divider-footer--xs"></div>
              </div>
          </h3>
          <h5>
            <v-icon color="#6937A7" class="mr-4">fas fa-envelope</v-icon>
            <a class="hidden-xs-only" href="mailto:insertemailhere@xyz.com" style="text-decoration: none; color: white"> alice.donea@email.com</a>
            <a class="hidden-sm-and-up" href="mailto:insertemailhere@xyz.com" style="text-decoration: none; color: black"> alice.donea@email.com</a>
          </h5>
          <h5 class="mt-10">
            <v-icon color="#6937A7" class="mr-4">fas fa-phone</v-icon>
            +(40) 0744522600
          </h5>
          <h5 class="mt-10">
            <v-icon color="#6937A7"  class="mr-4">fas fa-map-marker-alt</v-icon> 
            <a class="hidden-xs-only" href="https://www.google.com/maps/place/Clinica+Oana+Nicolau/@44.4237469,26.0902509,17z/data=!3m1!4b1!4m5!3m4!1s0x40b1ff12dab21e0d:0xc48651f3a85a155d!8m2!3d44.4237469!4d26.0924449" style="text-decoration: none; color: white">Strada George Georgescu 46, București</a> 
            <a class="hidden-sm-and-up" href="https://www.google.com/maps/place/Clinica+Oana+Nicolau/@44.4237469,26.0902509,17z/data=!3m1!4b1!4m5!3m4!1s0x40b1ff12dab21e0d:0xc48651f3a85a155d!8m2!3d44.4237469!4d26.0924449" style="text-decoration: none; color: black; margin-left: 5px">Strada George Georgescu 46, București</a>
          </h5>
          
          <div style="margin-left: -28px">
            <v-btn
              v-for="icon in icons"
              :key="icon"
              class="mx-5 mt-8"
              icon
            >
              <v-icon size="30px" color="#6937A7">
                {{ icon }}
              </v-icon>
            </v-btn> 
          </div>   
        </v-col>
        
        <v-col cols="12"  offset-lg="5" offset-md="4" offset-sm="3" align-self="center">
           <p> Some kind of disclaimer goes down here </p> 
        </v-col>    
    </v-row>


    
  </v-footer>
</template>

<script>
import GoogleMap from "../components/AddGoogleMap.vue";
  export default {
    name: 'Footer',

    components: {
    GoogleMap
  },

    data: () => ({
      icons: [
        'mdi-facebook',
        'mdi-linkedin',
        'mdi-instagram',
      ],
    }),
  }
</script>

<style lang="scss">
  $font-stack: Montserrat, sans-serif;

  .title-footer {
    font-family: $font-stack;
  }

  .footer-text{
    color: #FFFFFF;
  }
  .footer-text--xs{
    @media only screen and (max-width: 600px){
       color: black;
    }
   
  }

  .divider-footer{
     border-top: 2px solid white;
     width: 100px;
     margin-top: 10px;
     margin-left: 3px;
  }
  
  .divider-footer--xs{
    @media only screen and(max-width: 600px){
      border-top: 2px solid #6937A7;
      width: 100px;
      margin-top: 10px;
      margin-left: 3px;
    }
     
  }
</style>