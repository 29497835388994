<template>
    <div>
        <h1 style="margin-top: 100px"> This is Resources Page </h1>
    </div>
</template>

<script>
  export default {
    name: 'Resources',
    components: {}
  }
</script>