<template>
  <div>
    <h1 style="margin-top: 100px">This is an about page</h1>
    <!-- <v-btn @click="getData()">GET ARTICLES</v-btn> -->
    <div v-html="item" v-for="item in articles" :key="item">
<!--      {{ item }}-->
    </div>
  </div>
</template>

<script>
  export default {
    name: 'About',
    data() {
      return {
        articles: [],
      };
    },

    methods: {
      getData: function(){
        let self = this;
        this.$http(
                {url: 'http://localhost:8000/articles/', data: {}, method: 'GET' })
                .then(resp => {
                  // console.log(resp.data)
                  let data = resp.data.results
                  console.log(data)
                  data.forEach(function(element) {
                    console.log(element)
                    self.articles.push(element.article_text)
                  })
                  // for (let item in data){
                  //
                  // }
                })
      }

    },
  mounted() {
    this.getData();
  }
  }
</script>
