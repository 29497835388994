<template>
   <v-card 
    class="mx-auto"
    max-width="344">
    <v-img height="200px">
      <slot name="card-image"></slot>
    </v-img>
    <div class="pa-4">
     <v-card-title class="display-text">
       <slot name="card-title"></slot>
     </v-card-title> 

     <v-card-subtitle>
       <div class="divider">
        <div class="divider-card"></div>
      </div>
     </v-card-subtitle> 

     <v-card-text class="mt-6">
       <slot name="card-text"></slot>
     </v-card-text>
    </div> 
     <v-spacer></v-spacer>
      <v-btn
        color="black"
        text
        plain
        class="ma-4"
      >
        Learn more
        <v-icon class="ml-4" size="25px" color="primary">fas fa-long-arrow-alt-right</v-icon>
      </v-btn>
   </v-card>  
</template>

<script>

export default{
    name: 'VerticalCard',
}

</script>

<style scoped lang="scss">
   
</style>
