<template>
    <div>
        <h1 style="margin-top: 100px"> This is the login Page </h1>
        <form>
            <h3>Sign In</h3>

            <div class="form-group">
                <label>Email address</label>
                <input required type="text" class="form-control form-control-lg" v-model="id" />
            </div>

            <div class="form-group">
                <label>Password</label>
                <input required type="password" class="form-control form-control-lg" v-model="password" />
            </div>

            <v-btn type="submit" class="btn btn-dark btn-lg btn-block" @click="handleLogin">Sign In</v-btn>
        </form>
    </div>    
</template>

<script>
    // import axios from "axios";
    export default {
        name: 'Resources',
        data() {
            return {
                password: "",
                id:"",
            };
        },
        methods: {
            handleLogin: function(){
                // let self = this
                let id = this.id
                let password = this.password
                let data = {
                            "username": id,
                            "password": password
                }
                //displatch the login
                this.$store.dispatch('login', data)
                    .then(() => this.$router.push('/'))
                    .catch(err => console.log(err))
                // axios({
                //     url: "http://localhost:8000/api-auth-token/login",
                //     method: "POST",
                //     data:{
                //         "username": self.id,
                //         "password": self.password
                //     }
                // })
                //     .then(result => {
                //         console.log("RECEIVED THE FOLLOWING INFO FOR LOGIN")
                //         console.log(result)
                //     })
                //     .catch(err => {
                //         console.log(err);
                //     });
            },
        }


  }

</script>