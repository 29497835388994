import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';


Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: "fa",
      },
      theme: {
        themes: {
          light: {
            primary: "#C5B5D9",
          },
        },
      },
    });
